import React from 'react'

const defaultImage = "51.png"

export const ScraperImage = ({ segmentType }) => {
    // when segmentType is undefined => render default image
    let scraperImage = segmentType ? `/assets/img/scrapers/${segmentType}.png` : `/assets/img/scrapers/${defaultImage}`
    
    if (scraperImage == null || scraperImage == undefined) {
      // when segmentType is present but it does not exist an image for it => render default image
      scraperImage = `/assets/img/scrapers/${defaultImage}`
    }

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img style={{maxWidth: "60vw", height: "300px"}} src={scraperImage} />
        </div>
    );
}

export default ScraperImage